
import { navigate, graphql, Link } from 'gatsby'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Seo from '../components/Seo'
import SubLayout from '../components/layouts/SubLayout'

const OurTeamPage = ({ data }) => {
  const teamMembers = data.allSanityStaffMember.nodes    

  useEffect(() => {
    navigate('/') // Redirect to home page
  }, [])

  const teamMemberElements = teamMembers.map(({ firstName, lastName, role, bio, headshot }) => (
    <article key={`${firstName} ${lastName}`} className='team-member'>
      <img src={headshot.asset.url} alt={`${firstName} ${lastName}`} />
      <div className="content">
        <h2>{`${firstName} ${lastName}`}</h2>
        <h3>{role}</h3>
        <p>{bio}</p>
      </div>
    </article>
  ))

  return (
    <SubLayout>
      <Seo title='Our Team' pathname='/our-team' />
      <StyledOurTeamPage>
        <h1>Our Team</h1>

        <p className="breadcrumbs">
          <Link to='/'>Home</Link> {`>`}&nbsp;
          Our Team
        </p>

        <section id="team">
          {teamMemberElements}
        </section>
      </StyledOurTeamPage>
    </SubLayout>
  )
}

export const query = graphql`
  query TeamPageQuery {
    allSanityStaffMember(sort: {fields: rank}) {
      nodes {
        firstName
        lastName
        role
        bio
        headshot {
          asset { url }
        }
      }
    }
  }
`

const StyledOurTeamPage = styled.div`
  h1 {
    margin-bottom: 0.5rem;
    /* font-size: 3rem; */
    font-weight: 600;
  }

  section#team {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin-top: 1rem;

    article.team-member {
      display: flex;
      flex-flow: row wrap;
      gap: 2rem;

      img {
        flex: 0 0 auto;
        width: 200px;
        height: 200px;
      }

      div.content {
        flex: 1 1 180px;
      }

      h2, h3 {
        font-size: 1rem;
      }

      h2 {
        font-weight: 400;
      }

      h3 {
        margin-bottom: 1rem;
      }

      /* p {
        font-size: 0.9rem;
      } */
    }
  }

  @media (max-width: 480px) {
    text-align: center;
    /* padding: 0 1rem; */
    
    section#team {
      grid-template-columns: 1fr;
      grid-gap: 3rem;

      article.team-member {
        img {
          margin: auto;
        }

        p {
          text-align: justify;
        }
      }
    }
  }
`

export default OurTeamPage
